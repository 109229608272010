import { SITE_URL } from 'gatsby-env-variables';
import { Helmet } from 'react-helmet';

import thumbnailWebsite from './assets/thumbnail-website.png';
import { useLocale } from '../../i18n/useLocale';
import { useTranslate } from '../../i18n/useTranslate';

const siteUrlWithTrailingSlash = SITE_URL + '/';

export function SEOMetadata() {
  const t = useTranslate();
  const locale = useLocale();

  return (
    <Helmet>
      <meta name='description' content={t('site.metadata.description')} />
      <meta name='keywords' content={t('site.metadata.keywords')} />

      {/* <!--  Essential META Tags --> */}
      <meta property='og:title' content={t('site.metadata.title')} />
      <meta property='og:type' content='website' />
      <meta property='og:image' content={SITE_URL + thumbnailWebsite} />
      <meta property='og:url' content={siteUrlWithTrailingSlash} />
      <meta name='twitter:card' content='summary_large_image' />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta
        property='og:description'
        content={t('site.metadata.description')}
      />
      <meta property='og:site_name' content={t('site.metadata.title')} />
      <meta name='twitter:image:alt' content={t('site.metadata.imageAlt')} />

      {/* <!--  Non-Essential, But Required for Analytics --> */}
      <meta name='twitter:site' content='@bluejam_io' />

      {/* <!--  Other tags --> */}
      <meta property='og:locale' content={locale} />
      <meta property='og:locale:alternate' content='en' />
      <meta property='og:locale:alternate' content='no' />
    </Helmet>
  );
}

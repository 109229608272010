import type { PageProps } from 'gatsby';
import { type ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';

import { SEOMetadata } from '../components/SEOMetadata/SEOMetadata';
import en from '../i18n/locales/en.json';
import no from '../i18n/locales/no.json';

type PageContextType = {
  langKey: string;
  slug: string;
};

type Locale = 'en' | 'no';

const messagesByLocale = { en, no } as Record<Locale, Record<string, string>>;

export const wrapPageElement = ({
  element,
  props,
}: {
  element: ReactNode;
  props: PageProps<object, PageContextType>;
}) => {
  const locale = props.pageContext.langKey as Locale;

  const messages = messagesByLocale[locale] || messagesByLocale.en;

  return (
    <>
      <Helmet>
        <html lang={locale} />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1,maximum-scale=1'
        />
      </Helmet>
      <IntlProvider messages={messages} locale={locale} defaultLocale='en'>
        <SEOMetadata />
        {element}
      </IntlProvider>
    </>
  );
};

import { Global, useTheme } from '@emotion/react';

import { fontStyles } from './fontStyles';
import { getReactAriaCheckboxStyles } from './getReactAriaCheckboxStyles';
import { reactAriaModalStyles } from './reactAriaModalStyles';
import { resetStyles } from './resetStyles';

export function GlobalStyles() {
  const theme = useTheme();

  const styles = [
    fontStyles,
    resetStyles,
    reactAriaModalStyles,
    getReactAriaCheckboxStyles(theme),
  ];

  return <Global styles={styles} />;
}

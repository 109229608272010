import type { FirebaseAppSettings, FirebaseOptions } from 'firebase/app';
import { initializeApp } from 'firebase/app';

const options: FirebaseOptions = {
  apiKey: 'AIzaSyAVBZlGQSNyEvY05bKpdU_YHi3_b--oQZM',
  appId: '1:418050134190:web:2cfe971179eab5de80dd3f',
  measurementId: 'G-10QQ1N95R2',
  projectId: 'bluejam-marketing-eu',
};

const config: FirebaseAppSettings = {
  automaticDataCollectionEnabled: false,
};

export const firebaseApp = initializeApp(options, config);

import { COOKIE_HUB_SCRIPT } from 'gatsby-env-variables';
import { useEffect, useState } from 'react';

type Category =
  | 'necessary'
  | 'preferences'
  | 'analytics'
  | 'marketing'
  | 'uncategorized';

export function useCookieHub() {
  const [isCookieHubInitialized, setIsCookieHubInitialized] = useState(false);
  const [isAnalyticsCookiesAllowed, setIsAnalyticsCookiesAllowed] =
    useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = COOKIE_HUB_SCRIPT;

    script.onload = () => {
      window.cookiehub.load({
        onInitialise() {
          setIsAnalyticsCookiesAllowed(hasConsentedAnalytics());
          setIsCookieHubInitialized(true);
        },
        onStatusChange() {
          setIsAnalyticsCookiesAllowed(hasConsentedAnalytics());
        },
        onAllow(category: Category) {
          switch (category) {
            case 'analytics':
              setIsAnalyticsCookiesAllowed(true);
          }
        },
        onRevoke(category: Category) {
          switch (category) {
            case 'analytics':
              setIsAnalyticsCookiesAllowed(false);
          }
        },
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return { isCookieHubInitialized, isAnalyticsCookiesAllowed };
}

function hasConsentedAnalytics() {
  return window.cookiehub.hasConsented('analytics');
}

import { css } from '@emotion/react';

export const resetStyles = css`
  * {
    font-family: 'Sofia Pro', sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
`;

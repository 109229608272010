import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export function getReactAriaCheckboxStyles(theme: Theme) {
  return css`
    .react-aria-Checkbox {
      --label-color: var(--spectrum-alias-text-color);
      --deselected-color: gray;
      --deselected-color-pressed: dimgray;
      --selected-color: slateblue;
      --selected-color-pressed: lch(from slateblue calc(l - 10%) c h);
      --checkmark-color: white;
      --invalid-color: var(--spectrum-global-color-red-600);
      --invalid-color-pressed: var(--spectrum-global-color-red-700);

      display: flex;
      align-items: center;
      gap: 0.571rem;
      font-size: 1.143rem;
      cursor: pointer;

      .checkbox {
        width: 1.143rem;
        height: 1.143rem;
        border: 2px solid var(--deselected-color);
        border-radius: 4px;
        transition: all 200ms;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${theme.color.background};
      }

      & svg {
        width: 1rem;
        height: 1rem;
        fill: none;
        stroke: var(--checkmark-color);
        stroke-width: 3px;
        stroke-dasharray: 22px;
        stroke-dashoffset: 66;
        transition: all 200ms;
      }

      &[data-pressed] .checkbox {
        border-color: var(--deselected-color-pressed);
      }

      &[data-focus-visible] .checkbox {
        box-shadow:
          0 0 0 2px var(--spectrum-alias-background-color-default),
          0 0 0 4px var(--selected-color);
      }
      &[data-selected],
      &[data-indeterminate] {
        .checkbox {
          border-color: var(--selected-color);
          background: var(--selected-color);
        }

        &[data-pressed] .checkbox {
          border-color: var(--selected-color-pressed);
          background: var(--selected-color-pressed);
        }

        & svg {
          stroke-dashoffset: 44;
        }
      }

      &[data-indeterminate] {
        & svg {
          stroke: none;
          fill: var(--checkmark-color);
        }
      }
    }
  `;
}

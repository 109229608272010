import { ThemeProvider } from '@emotion/react';
import type { ReactNode } from 'react';

import { GlobalStyles } from '../components/GlobalStyles/GlobalStyles';
import { FirebaseAnalytics } from '../firebase/FirebaseAnalytics';
import { theme } from '../theme';

export const wrapRootElement = ({ element }: { element: ReactNode }) => {
  return (
    <>
      <FirebaseAnalytics />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {element}
      </ThemeProvider>
    </>
  );
};

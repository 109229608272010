import { css } from '@emotion/react';

import heptaSlabBold from './assets/HeptaSlab-Bold.woff2';
import heptaSlabRegular from './assets/HeptaSlab-Regular.woff2';
import heptaSlabSemibold from './assets/HeptaSlab-SemiBold.woff2';
import sofiaProBold from './assets/SofiaPro-Bold.woff2';
import sofiaProRegular from './assets/SofiaPro-Regular.woff2';
import sofiaProSemiBold from './assets/SofiaPro-SemiBold.woff2';

export const fontStyles = css`
  @font-face {
    font-family: 'Hepta Slab';
    font-weight: 400;
    src: url(${heptaSlabRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Hepta Slab';
    font-weight: 600;
    src: url(${heptaSlabSemibold}) format('woff2');
  }

  @font-face {
    font-family: 'Hepta Slab';
    font-weight: 700;
    src: url(${heptaSlabBold}) format('woff2');
  }

  @font-face {
    font-family: 'Sofia Pro';
    font-weight: 400;
    src: url(${sofiaProRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Sofia Pro';
    font-weight: 600;
    src: url(${sofiaProSemiBold}) format('woff2');
  }

  @font-face {
    font-family: 'Sofia Pro';
    font-weight: 700;
    src: url(${sofiaProBold}) format('woff2');
  }
`;

export const colors = {
  foreground: '#000000',
  background: '#FFFFFF',
  grey0: '#F7FAFF',
  grey100: '#F2F6FB',
  grey200_bg: '#E8ECF4',
  grey300_border: '#D8DEE8',
  grey400: '#C2CAD8',
  grey500: '#A7B1C1',
  grey600_ghost: '#8993A4',
  grey700: '#67707F',
  grey800_body: '#434953',
  grey900_headline: '#1F2226',
  blue100: '#F6F9FD',
  blue200: '#E0E9F9',
  blue300: '#BAD0F3',
  blue400: '#89AEEA',
  blue500: '#568ADE',
  blue600_link: '#2D6BCF',
  blue700: '#1253BB',
  blue800_primary: '#0542A4',
  blue900: '#00368C',
  grey_border: '#D8D8D8',
  lighter_grey: '#F7F7F7',
  lighter_grey_2: '#EAEAEA',
  lighter_grey_3: '#FAFAFA',
  royalBlue_primary: '#0148BE',
  oceanBlue_primary: '#0185FF',
  darkBlue_primary: '#002863',
  skyBlue_primary: '#51C2FF',
  seaBlue: '#036fd4',
  error: '#FF5959',
};

export type Color = keyof typeof colors;

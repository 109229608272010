import { initializeAnalytics } from 'firebase/analytics';

import { firebaseApp } from './firebaseApp';

export function initializeFirebaseAnalytics() {
  return initializeAnalytics(firebaseApp, {
    config: {
      allow_ad_personalization_signals: false,
      allow_google_signals: false,
    },
  });
}

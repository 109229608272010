import type { Analytics } from 'firebase/analytics';
import { setConsent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { useEffect, useState } from 'react';

import { initializeFirebaseAnalytics } from './initializeFirebaseAnalytics';
import { useCookieHub } from '../components/useCookieHub';

export function FirebaseAnalytics() {
  const [analytics, setAnalytics] = useState<Analytics>();
  const { isCookieHubInitialized, isAnalyticsCookiesAllowed } = useCookieHub();

  useEffect(() => {
    if (isCookieHubInitialized) {
      const analytics = initializeFirebaseAnalytics();
      setAnalytics(analytics);
    }
  }, [isCookieHubInitialized]);

  useEffect(() => {
    if (!analytics) return;

    setAnalyticsCollectionEnabled(analytics, isAnalyticsCookiesAllowed);

    setConsent({
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: isAnalyticsCookiesAllowed ? 'granted' : 'denied',
      functionality_storage: 'granted',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });
  }, [analytics, isAnalyticsCookiesAllowed]);

  return null;
}

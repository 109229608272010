import { css } from '@emotion/react';

export const reactAriaModalStyles = css`
  .react-aria-ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--visual-viewport-height);
    background: rgba(0 0 0 / 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &[data-entering] {
      animation: modal-fade 200ms;
    }

    &[data-exiting] {
      animation: modal-fade 150ms reverse ease-in;
    }
  }

  .react-aria-Modal {
    display: flex;

    &[data-entering] {
      animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    > section {
      :focus-visible {
        outline: none;
      }
    }
  }

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-zoom {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }
`;
